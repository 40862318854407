import {post, get} from 'ys-admin'

/**
 * 查询整改单详情
 * @param {Object} params
 * @returns {Object}
 */
export const amendInfo = function (params) {
  return get('/amend/GetInfo', params)
};


/**
 * 提交整改单
 * @param {Object} params
 * @returns {Object}
 */
export const amendCommit = function (params) {
  return post('/amend/Commit', params)
};
/**
 * 获取整改单列表
 * @param {Object} params
 * @returns {Object}
 */
export const getAmendList = function (params) {
  return get('/amend/List', params)
};

/**
 * 查询风险回复记录
 * @param {Object} params
 * @returns {Object}
 */
export const getProblemsRebackList = function (params) {
  return get('/amend/comment/reply/List', params)
};

/**
 * 查询风险记录
 * @param {Object} params
 * @returns {Object}
 */
export const getCommentList = function (params) {
  return get('/comment/List', params)
};

